<template>
  <b-container class="content-projects mb-5 ml-3">
    <b-row
      v-if="$store.state.auth.selectedBusiness"
      class="mt-4 mb-2 ml-1"
      align-v="end"
    >
      <b-col
        lg="8"
        class="d-inline-flex filters-row pr-0"
        style="border-bottom: 1px solid #E4E9F9"
      >
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :disabled="!counts.draft"
            :class="{'active': groupFilter === 'drafts'}"
            @click="groupFilter = 'drafts'"
          >
            Drafts({{ counts.draft }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :class="{'active': groupFilter === 'open'}"
            :disabled="!counts.open"
            @click="groupFilter = 'open'"
          >
            Open({{ counts.open }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :class="{'active': groupFilter === 'active'}"
            :disabled="!counts.active"
            @click="groupFilter = 'active'"
          >
            Active({{ counts.active }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :class="{'active': groupFilter === 'closed'}"
            :disabled="!counts.closed"
            @click="groupFilter = 'closed'"
          >
            Closed({{ counts.closed }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :class="{'active': groupFilter === 'all'}"
            @click="groupFilter = 'all'"
          >
            All({{ counts.all }})
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ProjectCard
          v-for="project in filteredProjects"
          :key="project.id"
          :project="project"
        />
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col
        v-if="!(filteredProjects || []).length"
        class="p-0"
      >
        <NoProjects />
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col class="text-center mt-5">
        <b-spinner
          v-if="!dataLoaded"
          label="Loading..."
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ProjectCard from '@/modules/business/components/ProjectCard'
import NoProjects from '@/modules/business/components/NoProjects'
import { PROJECT_STATUSES } from '@/helpers/statuses'

export default {
  name: 'NewProjects',
  components: {
    ProjectCard,
    NoProjects,
  },
  data () {
    return {
      PROJECT_STATUSES,
      dataLoaded: false,
      statusFilter: '',
      groupFilter: 'all',
      counts: {
        draft: 0,
        open: 0,
        active: 0,
        closed: 0,
        all: 0,
      },
      selected: null,
    }
  },
  computed: {
    ...mapState('projects', {
      projects: state => state.projects,
    }),
    ...mapGetters('projects', [
      'drafts',
      'open',
      'active',
      'closed',
    ]),
    /**
     * Get filtered project based on groupFilter
     */
    filteredProjects () {
      if (this.groupFilter === 'all') {
        return this.projects
      }
      if (this.groupFilter === 'drafts') {
        return this.drafts
      }
      if (this.groupFilter === 'open') {
        return this.open
      }
      if (this.groupFilter === 'active') {
        return this.active
      }
      return this.closed
    },
  },
  watch: {
    /**
     * Keep updating  them counters
     */
    filteredProjects () {
      this.setCounts()
    },
  },
  created () {
    this.setCounts()
    this.$store.dispatch('projects/getProjects')
      .then(() => {
        this.dataLoaded = true
        if (this.counts.draft > 0) {
          this.groupFilter = 'drafts'
        }
      })
  },
  methods: {
    /**
     * Set counts of all different projects
     */
    setCounts () {
      this.counts.draft = this.drafts.length
      this.counts.open = this.open.length
      this.counts.active = this.active.length
      this.counts.closed = this.closed.length
      this.counts.all = this.projects.length
    },

    saved () {
      this.dataLoaded = false
      this.$store.dispatch('projects/getProjects')
        .then(() => {
          this.setCounts()
          this.dataLoaded = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .content-projects {
    max-width: 74vw;
    .filters-row {
      justify-content: space-between;
    .filters {
      color:#475270;
      font-weight: 500;
      font-size: 14px;
    }
    .nav-link:hover,
    .nav-item.active,
    .nav-item.active .nav-link {
      border-bottom: 2px solid #EE8B7B;
    }
    }

    .filter-bar {
      top: 15px;
    .project-filter {
      max-width: 288px;
      font-weight: 400;
      font-size: 16px;
      color: #B3B6B8;
    }
    }
  }
</style>
