<template>
  <b-row class="mt-3 content">
    <b-col>
      <b-card class="project-card">
        <b-row>
          <b-col class="d-inline-flex pr-1">
            <div>
              <b-img
                v-if="$store.state.auth.selectedBusiness"
                src="/images/default-company-avatar.svg"
                alt="Profile pic"
                class="project-pic"
              />
              <b-img
                v-else
                :src="(project.business || {}).logo"
                alt="Profile pic"
                class="project-pic"
              />
            </div>
            <b-col
              class="p-0 ml-3"
            >
              <b-col class="p-0 h7 project-name">
                <router-link
                  :to="'/project/' + project.id"
                  class="text-decoration-none text-reset"
                >
                  {{ project.title }}
                </router-link>
                <span v-if="$store.state.auth.selectedBusiness">
                  <b-badge
                    :class="{
                      'status-starting' : startingStatuses.includes(project.status),
                      'status-active' : project.status === PROJECT_STATUSES.active.value,
                      'status-closed' : project.status === PROJECT_STATUSES.closed.value || project.status === PROJECT_STATUSES.paused.value
                    }"
                  >
                    {{ project.status }}
                  </b-badge>
                </span>
                <span v-if="$store.state.auth.selectedRecruiter">
                  <b-badge
                    :class="{
                      'status-starting' : startingStatuses.includes(project.status),
                      'status-active' : project.status === PROJECT_STATUSES.active.value,
                      'status-closed' : [PROJECT_STATUSES.closed.value, PROJECT_STATUSES.paused.value].includes(project.status)
                    }"
                  >
                    {{ project.match.status }}
                  </b-badge>
                </span>
              </b-col>
              <b-col class="p-0 general-info h7 mt-2">
                <span>
                  <i class="ri-hotel-line ri-lg pr-2" />
                  <label class="label-text">
                    {{ project.business.name }}
                  </label>
                </span>
                <span>
                  <i class="ri-map-pin-line ri-lg ml-3 pr-2" />
                  <label class="label-text">
                    {{ project.business.headquarters }}
                  </label>
                </span>
                <span>
                  <i class="ri-building-3-line ri-lg ml-3 pr-2" />
                  <label class="label-text">
                    {{ project.business.industries.map(i => i.label).join(', ') }}
                  </label>
                </span>
              </b-col>
              <b-col class="p-0 created mt-1">
                <span>
                  {{ "Created " + formatDate(project.created_at) }}
                </span>
              </b-col>
            </b-col>
          </b-col>
        </b-row>
        <b-row
          v-if="['admin', 'manager'].includes($store.state.auth.user.role)"
          class="mt-3"
        >
          <b-col>
            <b-form-group
              id="input-group-5"
              label-for="input-5"
            >
              <b-form-select
                id="input-5"
                v-model="localProject.status"
                name="status"
                :options="statusOptions"
                required
              />
              <b-form-select
                id="input-5"
                v-model="localProject.closed_reason"
                name="status"
                :options="closedReasonOptions"
                required
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-btn
              variant="primary"
              @click="save"
            >
              Save
            </b-btn>
          </b-col>
          <b-col
            v-if="['admin', 'manager'].includes($store.state.auth.user.role)"
            cols="1"
            class="float-right"
          >
            <b-btn
              class="float-right p-1"
              @click="deleteProject(project.id)"
            >
              <img
                src="/images/delete24px.png"
                alt="del-btn"
              >
            </b-btn>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { formatDate } from '@/helpers/date'
import axios from '@/helpers/cleanAxios'
import { CLOSING_REASONS, PROJECT_STATUSES, statusToArray } from '@/helpers/statuses'

export default {
  name: 'ProjectCard',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      PROJECT_STATUSES,
      startingStatuses: [
        PROJECT_STATUSES.draft.value,
        PROJECT_STATUSES.open.value,
      ],
      localProject: {},
      statusOptions: statusToArray(PROJECT_STATUSES),
      closedReasonOptions: [
        {
          text: null,
          value: null,
        },
        ...statusToArray(CLOSING_REASONS),
      ],
    }
  },
  watch: {
    project: {
      handler: function (newStatus) {
        this.localProject = newStatus
      },
      immediate: true,
    },
  },
  methods: {
    formatDate,
    save () {
      axios.patch(`/admin/v2/projects/${this.project.id}`, this.localProject)
        .then(() => {
          this.$emit('saved')
        }).then(() => {
          alert('Project Status Changed')
        })
    },
    deleteProject (projectId) {
      const confirmed = confirm('U sure u want to delete the project?')
      if (confirmed) {
        axios.delete(`/admin/v2/projects/${projectId}`)
          .then(() => {
            window.location.reload()
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .content {
      max-width: 74vw;
    }
    .project-card {
      background-color: #FFF;
      box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
      border-radius: 12px;
      border: none;
      .project-pic {
        width: 80px;
        height: 80px;
        object-fit: contain;
        border-radius: 8px;
        border: 2px solid #605D9F;
      }
      .project-name {
        font-size: 18px;
      }
      .general-info {
        font-weight: 500px;
        font-size: 12px;
        line-height: 14.4px;
      .label-text {
        vertical-align: text-top;
        font-weight: 500;
      }
      }
      .badge.status-badge {
        background-color: #E0FFE7;
        color: #00A725;
      }
      .created {
        font-weight: 400;
        font-size: 12px;
        color: #636077;
      }
      @media (max-width: 1116px) {
        .project-name {
          font-size: 15px;
        }
      }
  }
</style>
