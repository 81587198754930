var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mt-3 content"},[_c('b-col',[_c('b-card',{staticClass:"project-card"},[_c('b-row',[_c('b-col',{staticClass:"d-inline-flex pr-1"},[_c('div',[(_vm.$store.state.auth.selectedBusiness)?_c('b-img',{staticClass:"project-pic",attrs:{"src":"/images/default-company-avatar.svg","alt":"Profile pic"}}):_c('b-img',{staticClass:"project-pic",attrs:{"src":(_vm.project.business || {}).logo,"alt":"Profile pic"}})],1),_c('b-col',{staticClass:"p-0 ml-3"},[_c('b-col',{staticClass:"p-0 h7 project-name"},[_c('router-link',{staticClass:"text-decoration-none text-reset",attrs:{"to":'/project/' + _vm.project.id}},[_vm._v(" "+_vm._s(_vm.project.title)+" ")]),(_vm.$store.state.auth.selectedBusiness)?_c('span',[_c('b-badge',{class:{
                    'status-starting' : _vm.startingStatuses.includes(_vm.project.status),
                    'status-active' : _vm.project.status === _vm.PROJECT_STATUSES.active.value,
                    'status-closed' : _vm.project.status === _vm.PROJECT_STATUSES.closed.value || _vm.project.status === _vm.PROJECT_STATUSES.paused.value
                  }},[_vm._v(" "+_vm._s(_vm.project.status)+" ")])],1):_vm._e(),(_vm.$store.state.auth.selectedRecruiter)?_c('span',[_c('b-badge',{class:{
                    'status-starting' : _vm.startingStatuses.includes(_vm.project.status),
                    'status-active' : _vm.project.status === _vm.PROJECT_STATUSES.active.value,
                    'status-closed' : [_vm.PROJECT_STATUSES.closed.value, _vm.PROJECT_STATUSES.paused.value].includes(_vm.project.status)
                  }},[_vm._v(" "+_vm._s(_vm.project.match.status)+" ")])],1):_vm._e()],1),_c('b-col',{staticClass:"p-0 general-info h7 mt-2"},[_c('span',[_c('i',{staticClass:"ri-hotel-line ri-lg pr-2"}),_c('label',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.project.business.name)+" ")])]),_c('span',[_c('i',{staticClass:"ri-map-pin-line ri-lg ml-3 pr-2"}),_c('label',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.project.business.headquarters)+" ")])]),_c('span',[_c('i',{staticClass:"ri-building-3-line ri-lg ml-3 pr-2"}),_c('label',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.project.business.industries.map(function (i) { return i.label; }).join(', '))+" ")])])]),_c('b-col',{staticClass:"p-0 created mt-1"},[_c('span',[_vm._v(" "+_vm._s("Created " + _vm.formatDate(_vm.project.created_at))+" ")])])],1)],1)],1),(['admin', 'manager'].includes(_vm.$store.state.auth.user.role))?_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-5","label-for":"input-5"}},[_c('b-form-select',{attrs:{"id":"input-5","name":"status","options":_vm.statusOptions,"required":""},model:{value:(_vm.localProject.status),callback:function ($$v) {_vm.$set(_vm.localProject, "status", $$v)},expression:"localProject.status"}}),_c('b-form-select',{attrs:{"id":"input-5","name":"status","options":_vm.closedReasonOptions,"required":""},model:{value:(_vm.localProject.closed_reason),callback:function ($$v) {_vm.$set(_vm.localProject, "closed_reason", $$v)},expression:"localProject.closed_reason"}})],1)],1),_c('b-col',[_c('b-btn',{attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save ")])],1),(['admin', 'manager'].includes(_vm.$store.state.auth.user.role))?_c('b-col',{staticClass:"float-right",attrs:{"cols":"1"}},[_c('b-btn',{staticClass:"float-right p-1",on:{"click":function($event){return _vm.deleteProject(_vm.project.id)}}},[_c('img',{attrs:{"src":"/images/delete24px.png","alt":"del-btn"}})])],1):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }